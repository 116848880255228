
/**
 * ¡IMPORTANTE!
 *
 * Este archivo es autogenerado por el script "version.js", no
 * modificarlo manualmente, ni pushearlo al repo.
 *
 */

export const VERSION = {
    "dirty": false,
    "raw": "4c5b1658b",
    "hash": "4c5b1658b",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "4c5b1658b",
    "semverString": null,
    "version": "2.24.5"
};

