import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../services/environment.service';
import { Moneda } from '../../shared/models/moneda.model';
import { TipoInstrumento } from '../../shared/models/tipo-instrumento.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pais } from 'src/app/shared/models/pais.model';
import parametrosNegociosDefault from 'src/app/shared/constants/parametrosNegociosDefault.json';
import { TestInversorPerfil } from 'src/app/shared/models/perfilinversor.model';
import { Parametro } from 'src/app/shared/models/parametros.model';

interface ParametrosNegocioResponse {
    Monedas: Moneda[];
    TiposInstrumentos: TipoInstrumento[];
    Paises: [];
    testInversorPerfiles: [];
    parametros: Parametro[];
}

@Injectable()
export class ParametrosNegocioService {
    private _basePath = this._envService.env.BaseUrlService + '/comun/parametrosnegocio';
    private monedasSubject: BehaviorSubject<Moneda[]>;
    private tiposInstrumentosSubject: BehaviorSubject<TipoInstrumento[]>;
    private paises: Pais[] = parametrosNegociosDefault.PaisesDefault;
    private perfilesInversor: TestInversorPerfil[] = parametrosNegociosDefault.testInversorPerfilesDefault;
    private _parametros: Parametro[] = parametrosNegociosDefault.ParametrosDefault;

    get listadoPaises(): Pais[] {
        return this.paises;
    }

    get tiposInstrumentos(): TipoInstrumento[] {
        return this.tiposInstrumentosSubject.value;
    }

    get listadoPerfilesInversor(): TestInversorPerfil[] {
        return this.perfilesInversor;
    }

    get currentCurrencies(): Moneda[] {
        return this.monedasSubject.value;
    }

    constructor(private http: HttpClient, private _envService: EnvironmentService) {
        this.monedasSubject = new BehaviorSubject(parametrosNegociosDefault.MonedasDefault);
        this.tiposInstrumentosSubject = new BehaviorSubject(parametrosNegociosDefault.TiposInstrumentosDefault);
    }

    monedas$(): Observable<Moneda[]> {
        return this.monedasSubject.asObservable();
    }

    listadoMonedas(filtrar?: any[]): Moneda[] {
        const monedas = this.monedasSubject.value;
        if (filtrar === undefined) {
            return monedas;
        }
        return monedas.filter(
            m => filtrar.includes(m.idMoneda) || filtrar.includes(m.Simbolo) || filtrar.includes(m.Descripcion)
        );
    }

    perfilInversor(idPerfil: number): string {
        return this.perfilesInversor.find(p => p.idPerfil === idPerfil).Descripcion;
    }

    tiposInstrumentos$(): Observable<TipoInstrumento[]> {
        return this.tiposInstrumentosSubject.asObservable();
    }

    loadBussinessParams(): void {
        this.http.get(this._basePath).subscribe((response: ParametrosNegocioResponse) => {
            this.monedasSubject.next(response.Monedas || []);
            this.paises = response.Paises;
            this.perfilesInversor = response.testInversorPerfiles;
            const tiposInstrumentos = response.TiposInstrumentos || [];

            tiposInstrumentos.forEach(tipoInstrumento => {
                tipoInstrumento.Descripcion =
                    tipoInstrumento.Descripcion === 'Renta Fija' ? 'Bonos' : tipoInstrumento.Descripcion;
            });

            this._parametros = response.parametros.map((p: Parametro) => {
                if (['AforoMonto', 'AforoMontoMEP'].includes(p.Nombre)) {
                    const _aforo = Number(p.Valor);
                    if (!isNaN(_aforo)) {
                        p.Valor = _aforo;
                    }
                }
                return p;
            });
            this.tiposInstrumentosSubject.next(tiposInstrumentos);
        });
    }
    getMonedaColor(currency) {
        const _curreny = this.getCurrency(currency);
        return _curreny ? _curreny.color : '';
    }

    getMonedaName(currency): string {
        const _curreny = this.getCurrency(currency);
        return _curreny ? _curreny.Descripcion : '';
    }

    getMonedaSymbol(currency) {
        const _curreny = this.getCurrency(currency);
        return _curreny ? _curreny.Simbolo : '';
    }

    getCurrency(currency) {
        return this.monedasSubject.value.find(
            e => e.idMoneda === currency || e.Simbolo === currency || e.Descripcion === currency
        );
    }

    getMontoAforo(): number {
        return this._parametros.find((p: Parametro) => p.Nombre === 'AforoMonto').Valor as number;
    }

    getMontoAforoMEP(): number {
        return this._parametros.find((p: Parametro) => p.Nombre === 'AforoMontoMEP').Valor as number;
    }

    getColor(descripcion) {
        const concatenada = this.monedasSubject.value
            .map(this.mapParameters)
            .concat(this.tiposInstrumentosSubject.value.map(this.mapParameters));

        return concatenada.find(e => e.Descripcion === descripcion)?.color;
    }
    private mapParameters(objeto: Moneda | TipoInstrumento) {
        return {
            Descripcion: objeto.Descripcion,
            color: objeto.color
        };
    }
}
