import { Injectable } from '@angular/core';
import { BlzTranslateConfig } from 'src/app/shared/models/translate-config.model';

@Injectable()
export class BlzEnvironment {
    production: boolean;
    checkForUpdates: boolean;
    maintenance: boolean;
    AbrirCuentaUrl: string;
    BaseUrlService: string;
    BaseUrlWebSocket: string;
    BaseUrlLoggerService: string;
    LoggerAppName: string;
    LoggerEnabled: boolean;
    UserBehaviourEnabled: boolean;
    BaseUrlImage: string;
    BaseUrlWidgetsLibs: string;
    TV_clientID: string;
    TV_urlprefix: string;
    TV_library: string;
    WidgetTradingView: string;
    WidgetChatElement: string;
    assetsUrl: string;
    assetsEmbebidosUrl: string;
    minutesForCheckMarket: number;
    alertsCloseDelay: number;
    defaultErrorMsg: string;
    amchartsLicense: string;
    encrypterSecret: string;
    timeRefreshDataMinutes: number;
    timeDelaySeconds: number;
    requestsSource: string;
    TipoDispositivo: string;
    FaqSupport: boolean;
    ThemeSupport: boolean;
    RecomendacionesSupport: boolean;
    RankingSupport: boolean;
    DolarMepSupport: boolean;
    showIdCuenta: boolean;
    local: boolean;
    consoleLogging: boolean;
    showUpdateDialog: boolean;
    automaticUpdates: boolean;
    renderItemsCotizacionByScroll: boolean;
    translateConfig: BlzTranslateConfig;
    WidgetCalificacionElement: string;
    showWidgetCalificacion: boolean;
    WidgetFaqsElement: string;
    isCustomerImpersonate: boolean;
    showNewViewFormOperar: boolean;
    showRecommendationsAfterOperation: boolean;
    workerassetsUrl: string;
    showUltimosVistos: boolean;
    assetsTickersURL: string;
    showSocial: boolean;
    showWidgetGenBotAi: boolean;
    WidgetGenBotAiUri: string;
    baseUrlInsights: string;
    baseUrlDeeplink: string;
    showTenenciaDetalleInstrumento: boolean;
    showBtnCreateWatchlistSeguimiento: boolean;
    showBtnCreateAlertaSeguimiento: boolean;
    operacionRapidaBuscadorEspecie: boolean;
    urlRecomendacionesSeguridad: string;
    showAddToFavoritos: boolean;
    showRangoPreciosDiarios: boolean;
    showTenenciaDolarizada: boolean;
    showSentimientoMercado: boolean;
    enableUndoWatchlist: boolean;
    showTabOptionsInstrumentDetail: boolean;
    enableFilterBanners: boolean;
}

/**
 * Servicio encargado de proveer el envionment de la aplicacion.
 */
@Injectable()
export class EnvironmentService {
    constructor(private _blzEnvironment: BlzEnvironment) {}
    get env(): BlzEnvironment {
        return this._blzEnvironment;
    }
}
