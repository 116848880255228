import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';
import { CuentaService } from './cuenta.service';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export interface Banner {
    accion: string;
    accionjson: any;
    backgroundcolor: string;
    footerbackgroundcolor: string;
    idbanner: number;
    textodescripcionpie: string;
    textoimagen: string;
    textotitulopie: string;
    tipoaccion: number;
    urlimagen: string;
    urlimagenlarge: string;
    urlimagensmall: string;
    idnegocio: string;
    menordeedad?: number;
    idtipobanner?: number;
}

export interface BannerResponse {
    banners: Banner[];
}

@Injectable()
export class BannersService implements OnDestroy {
    private _basePath = this._envService.env.BaseUrlService + '/banners';
    private _onBannersChange = new BehaviorSubject<Banner[]>([]);
    private banners = [];

    get currentBanners(): any[] {
        return this._onBannersChange.getValue();
    }

    constructor(
        private http: HttpClient,
        private _envService: EnvironmentService,
        private _cuentaService: CuentaService,
        private _authService: AuthService
    ) {}

    ngOnDestroy() {
        this._onBannersChange.unsubscribe();
    }

    getBanners(): Observable<Banner[]> {
        return this._onBannersChange.asObservable();
    }

    loadBanners(): void {
        this.http
            .get<BannerResponse>(this._basePath, { params: { todos: 1 } })
            .pipe(catchError((err: any) => []))
            .subscribe((response: any) => {
                this.banners = response.banners;
                this.refreshBanners();
            });
    }

    refreshBanners(): void {
        const cuenta = this._cuentaService.getCuentaSeleccionada();
        this._onBannersChange.next(this.banners.filter(b => b.idnegocio === cuenta?.idNegocio));
    }

    getBannersDestacados(): Observable<Banner[]> {
        return this._onBannersChange.asObservable().pipe(
            map(banners => {
                const idEdadCuenta = this._authService.idEdadCuenta();
                return banners.filter(b => {
                    if (b?.idtipobanner === 2) {
                        if (idEdadCuenta === 0) {
                            return [0, 2].includes(b.menordeedad);
                        } else if (idEdadCuenta === 1) {
                            return [0, 1].includes(b.menordeedad);
                        }
                    }
                    return false;
                });
            })
        );
    }
}
