import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../services/environment.service';
import { EventoCorporativo } from '../../shared/models/evento-corporativo.model';

@Injectable()
export class CorporativosService {
    private _basePath = `${this._envService.env.BaseUrlService}/corporativo`;

    constructor(private _envService: EnvironmentService, private _http: HttpClient) {}

    getTickerEvents(tickerId: string): Observable<EventoCorporativo[]> {
        return this._http.get<EventoCorporativo[]>(`${this._basePath}/eventos?ticker=${tickerId}`);
    }
}
